import { default as donatezxqRNXRGx6Meta } from "/home/runner/work/child-of-light/child-of-light/pages/donate.vue?macro=true";
import { default as indexWNn8lr2R9IMeta } from "/home/runner/work/child-of-light/child-of-light/pages/index.vue?macro=true";
import { default as offer_45agreementh0T2JEcw61Meta } from "/home/runner/work/child-of-light/child-of-light/pages/offer-agreement.vue?macro=true";
import { default as privacy_45policyUM3KML9NRgMeta } from "/home/runner/work/child-of-light/child-of-light/pages/privacy-policy.vue?macro=true";
import { default as report7QAT0NevE3Meta } from "/home/runner/work/child-of-light/child-of-light/pages/report.vue?macro=true";
export default [
  {
    name: "donate",
    path: "/donate",
    component: () => import("/home/runner/work/child-of-light/child-of-light/pages/donate.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/runner/work/child-of-light/child-of-light/pages/index.vue")
  },
  {
    name: "offer-agreement",
    path: "/offer-agreement",
    component: () => import("/home/runner/work/child-of-light/child-of-light/pages/offer-agreement.vue")
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    component: () => import("/home/runner/work/child-of-light/child-of-light/pages/privacy-policy.vue")
  },
  {
    name: "report",
    path: "/report",
    component: () => import("/home/runner/work/child-of-light/child-of-light/pages/report.vue")
  }
]